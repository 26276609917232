<template>
  <div class="fragment">
    <div class="content-top-line content-top-line--with-bottom-line"
         v-bind:class="{'admin-edit-item': $store.getters.getUserProfile.isAdmin || checkImpersonate()}">
        <div class="admin-edit"
              @click="editTranslate([
                'invoices_NovaPoshta',
                'invoices_CDEK',
                'invoices_Orders',
                'invoices_Consolidation',
                'invoices_DHLFedExTNT',
                'invoices_FedEx',
                'invoices_FBA',
                'invoices_FBAReturns',
                'invoices_Other',
              ])"></div>
      <div class="content-tabs content-tabs--separator content-tabs--scrolling"
           v-bind:class="{'loading-tabs': $store.getters.getInvoicesLoading === true,}">
        <template v-for="(item, index) in navTabs">
          <div class="content-tabs__item"
               :key="index"
               v-if="checkVisibleItem(item) && item.permissionVisible"
               v-bind:class="{'active': item.active}"
               @click="$emit('changeTab', item.name)"
          >
            <div class="content-tabs__ico">
              {{ $t(`${item.label}.localization_value.value`) }}
            </div>
          </div>
        </template>

        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--Orders-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--Consolidation-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--DHL, FedEx, TNT-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--FBA-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--FBA Returns-->
        <!--</div>-->
        <!--</div>-->
        <!--<div class="content-tabs__item">-->
        <!--<div class="content-tabs__ico">-->
        <!--Other-->
        <!--</div>-->
        <!--</div>-->
      </div>

      <div class="scrolling-tabs" v-if="hasScrolling">
        <div class="scrolling-tabs-left hide" @click="scrollTabsLeft">❮❮</div>
        <div class="scrolling-tabs-right hide" @click="scrollTabsRight">❯❯</div>
      </div>

      <div class="content-top-line-wrap">
        <ToggleFilterButton
                :count="countFilterParams"
                :showFilter="showFilter"
                @toggleFilter="$emit('toggleFilter')"
        />
      </div>
    </div>

    <div class="light-bg-block">
      <div class="btn-left-block ">
        <div class="btn-left-block-i">
          <DefaultCheckbox
                  :label="$t('common_selectAll.localization_value.value')"
                  @input="(val) => $emit('selectAll', val)"
          />
        </div>
        <div class="btn-left-block-i">

          <div class="btn-left-block__list">
            <div class="btn-left-block__list__i">
              <PrintInvoice
                      class="btn-left-block-i--link"
                      :datePicker="true"
                      :datePickerRange="false"
                      :datePickerFormat="'YYYY-MM-DD'"
                      @changeDate="changeDate"
                      @datePickerBtn="$emit('printInvoice', dateInvoice)"
              />
            </div>

            <div class="btn-left-block__list__i" v-if="isAdmin || isPoland || isUserAlternativeContactPoland">
              <PrintInvoice
                      class="btn-left-block-i--link"
                      :datePicker="true"
                      :datePickerRange="false"
                      :datePickerTxt="$t('common_InvoiceReport.localization_value.value')"
                      :datePickerFormat="'YYYY-MM-DD'"
                      :datePickerType="'month'"
                      :disabledDates="'currentAndNextMonth'"
                      @changeDate="changeDate"
                      @datePickerBtn="downloadInvoiceReport"
              />
            </div>

<!--            <div class="btn-left-block__list__i" >-->
<!--              <TopTableBoxesBtn-->
<!--                  class="btn-left-block-i&#45;&#45;link"-->
<!--                  :title="'common_InvoiceReport'"-->
<!--                  @click.native="downloadInvoiceReport"-->
<!--              />-->
<!--            </div>-->
          </div>

        </div>
      </div>

      <div v-if="isAdmin && navTabs.other.active" class="btn-right-block">
        <router-link :to="$store.getters.GET_PATHS.financeInvoicesCreate">
          <MainButton class="btn-fit-content ml-2"
                      :value="$t('common_AddNew.localization_value.value')"
                      :ico="true"
          >
            <template slot="ico">
              <PlusIconSVG class="btn-icon-plus"/>
            </template>
          </MainButton>
        </router-link>
      </div>

    </div>

    <DownloadingPopup
        v-if="isReportGeneration"
    />

  </div>
</template>

<script>
  import ToggleFilterButton from "../../../../../../UI/buttons/ToggleFilterButton/ToggleFilterButton";
  import DefaultCheckbox from "../../../../../../UI/checkboxes/DefaultCheckbox/DefaultCheckbox";
  import PrintInvoice from "../../../../../../coreComponents/PrintInvoice/PrintInvoice";
  import MainButton from "../../../../../../UI/buttons/MainButton/MainButton";
  import PlusIconSVG from '../../../../../../../../public/img/UI-group/plus-icon.svg?inline'
  // import TopTableBoxesBtn from "@/components/coreComponents/Buttons/TopTableBoxesBtn/TopTableBoxesBtn";
  import {routeFilter} from "@/mixins/routeFilterMixins/routeFilter";
  import DownloadingPopup from "@/components/modules/InvoicesModule/popups/DownloadingPopup/DownloadingPopup";
  import {tabsScrollingMixin} from "@/mixins/globalMixins/tabsScrollingMixin";


  export default {
    name: "InvoicesTableHead",
    components: {
      DownloadingPopup,
      // TopTableBoxesBtn,
      MainButton,
      ToggleFilterButton,
      DefaultCheckbox,
      PrintInvoice,
      PlusIconSVG
    },

    mixins: [routeFilter, tabsScrollingMixin],

    props: {
      showFilter: Boolean,
      countFilterParams: Number,
      navTabs: Object,
    },

    data() {
      return {
        isModalWhatOrderCreatePopup: false,
        isModalWrongOrdersForPopup: false,
        value: [],
        orderPlanner: false,
        openCalendar: false,
        isReportGeneration: false,
        dateInvoice: this.$moment().format('YYYY-MM-DD'),
      }
    },

    computed:{

    },

    mounted() {
      console.log(this.navTabs);
    },

    methods: {

      downloadInvoiceReport() {
        this.isReportGeneration = true
        this.$store.dispatch('getPolandInvoiceReport', this.$moment(this.dateInvoice).format('DD-MM-YYYY')).then((response) => {
          if (this._.has(response, 'data') && response.status === this.$store.getters.GET_ERRORS.SUCCESS_CODE) {
            this.globalDownloadBase64File('application/docx', response.data.data.fileContentBase64, 'Invoice Report.docx')
          } else {
            this.openNotify('error', 'common_notificationUndefinedError')
          }
          this.isReportGeneration = false
        })
      },

      goToPrintInvoiceReport() {
        let routeData = this.$router.resolve({
          path: this.$store.getters.GET_PATHS.generatePdfFile + '/poland-invoice-report',
          // query: {
          //   ids: postData.orderIds,
          //   format: postData.format
          // }
        })

        window.open(routeData.href, '_blank');

      },

      checkVisibleItem(val) {
        let newItem = val

        if(this.isAdmin) return true

        if(this._.has(newItem, 'hiddenOptionArr') &&
            newItem.hiddenOptionArr.indexOf('isEasyOrder') > -1 &&
            this.isUserHasEasyOrder) {
          return false
        }

        if(this._.has(newItem, 'hiddenOptionArr') &&
          (newItem.hiddenOptionArr.indexOf('isBelarus') > -1 &&
            newItem.hiddenOptionArr.indexOf('isSNGGroup') > -1)){
          // console.log('isBelarus', newItem.hiddenOptionArr.indexOf('isBelarus') > -1);
          return (this.isBelarus || this.isSNGGroup)
        }

        if(this._.has(newItem, 'hiddenOptionArr') && newItem.hiddenOptionArr.indexOf('isBelarus') > -1){
          // console.log('isBelarus', newItem.hiddenOptionArr.indexOf('isBelarus') > -1);
          return this.isBelarus
        }
        if(this._.has(newItem, 'hiddenOptionArr') && newItem.hiddenOptionArr.indexOf('isSNGGroup') > -1){
          // console.log('isSNGGroup', newItem.hiddenOptionArr.indexOf('isSNGGroup') > -1);
          return this.isSNGGroup
        }
        if(this._.has(newItem, 'hiddenOptionArr') && newItem.hiddenOptionArr.indexOf('isUkrainian') > -1){
          // console.log('isUkrainian', newItem.hiddenOptionArr.indexOf('isUkrainian') > -1);
          return this.isUkrainian
        }
        return true
      },

      openCalendarFunc() {
        if (!this.openCalendar && this.orderPlanner) {
          this.orderPlanner = false
          this.openCalendar = false
        } else {
          this.openCalendar = true
        }
      },

      cancelCalendar() {
        this.openCalendar = false
      },

      showWhatOrderCreatePopup() {
        this.isModalWhatOrderCreatePopup = true
      },

      closeWhatOrderCreatePopup() {
        this.isModalWhatOrderCreatePopup = false
      },

      changeOrderPlannerDate() {
        this.orderPlanner = true
        this.openCalendar = false
      },

      changeDate(value) {
        console.log(value);
        this.dateInvoice = value
      },


      showWrongOrdersForPopup() {
        this.isModalWrongOrdersForPopup = true
      },

      closeWrongOrdersForPopup() {
        this.isModalWrongOrdersForPopup = false
      },

    }
  }
</script>

<style scoped>
  .content-tabs__item {
    padding: 0 9px 14px;
  }
</style>
